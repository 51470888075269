<template>
  <div class="GrantApplicationTenantForm-wrapper">
    <form-panel
      :submitUrl="submitUrl"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block title="设置项目授权应用">
          <el-form-item label="项目" prop="communityId" :rules="[{ required: true, message: '请选择项目', trigger: 'change' }]">
            <v-select2 v-model="form.communityId" placeholder="查询项目" v-bind="communityParams" @onChange="communityChange" />
          </el-form-item>
          <el-form-item label="授权应用">
            <chosen-list-panel :list.sync="form.subIds" textAs="subName" @select="select" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="应用列表"
      :isShow.sync="showMultiSelect"
      :searchUrl="getApplicationListURL"
      :headers="headers"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.subIds">
        <template #searchSlot>
          <v-input label="应用名称" v-model="searchParams.subName" />
          <v-select label="应用类别" v-model="searchParams.subHandle" :options="subHandleOps" />
          <v-select label="应用类型" v-model="searchParams.subType" :options="subTypeOps" />
          <v-input label="应用描述" v-model="searchParams.subNote" />
        </template>
    </multi-select>
  </div>
</template>

<script>
import { grantApplicationURL, getApplicationListURL, getGrantApplicationListURL } from './api'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, statusMap } from '../map'
import { Col2Detail, Col2Block, ChosenListPanel, MultiSelect } from 'components/bussiness'
import { normalCommunityParams } from 'common/select2Params'

export default {
  name: 'GrantApplicationTenantForm',
  components: {
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect
  },
  data () {
    return {
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      communityParams: normalCommunityParams,
      showMultiSelect: false,
      form: {
        communityId: '',
        subIds: []
      },
      submitUrl: grantApplicationURL,
      getApplicationListURL,
      headers: [
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'subHandleText',
          label: '应用类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => statusMap[row.subStatus]
        },
        {
          prop: 'subNote',
          label: '应用描述'
        }
      ],
      searchParams: {
        communityId: undefined,
        subName: '',
        subHandle: undefined,
        subType: undefined,
        subNote: ''
      },
      responseParams: {
        id: 'id',
        name: 'subName'
      },
      responseKey: {
        id: 'id',
        name: 'subName'
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置项目授权应用')
  },
  methods: {
    select () {
      if (this.form.communityId) {
        this.showMultiSelect = true
      } else {
        this.$message.info('请先选择项目信息')
      }
    },
    communityChange (option) {
      const communityId = option && option.id
      this.searchParams.communityId = communityId
      if (communityId) {
        this.$axios.get(getGrantApplicationListURL, {
          params: {
            communityId
          }
        }).then(res => {
          if (res.status === 100) {
            const data = res.data || []
            this.form.subIds = data
          }
        })
      } else {
        this.form.subIds = []
      }
    },
    submitBefore (data) {
      let subIds = []
      data.subIds.forEach(item => {
        subIds.push(item.id)
      })
      data.subIds = subIds
      return true
    }
  }
}
</script>
